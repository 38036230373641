import React from "react";
import { RedocStandalone } from "redoc";
function App() {
  const url = "https://api.cloudmix.tv/openapi.yaml";
  return (
    <>
      <RedocStandalone specUrl={url} />
    </>
  );
}

export default App;
